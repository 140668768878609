import React from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import { LegalDocumentsQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import { HeadingBanner, SubscriptionPanel } from "../../components/shared"
import { IMAGES } from "../../constants"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    page_heading,
    heading_description,
    banner_image,
    legal_documents,
  } = LegalDocumentsQuery(language)
  return (
    <>
      <Layout>
        <HeadingBanner heading={page_heading} banner_image={banner_image} />

        <section
          className="information-box quickStats p-top-140 p-bottom-60"
          style={{ paddingTop: "10px" }}
        >
          <div className="information-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 legal-documents">
                  <div className=" card shadow-lg-2 border-0">
                    <div className="card-header">
                      <h5
                        className="m-bottom-0"
                        style={{ fontWeight: "400", fontSize: "17px" }}
                      >
                        {heading_description[0].text}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="job-contents p-top-20 p-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive table-bordered">
                  <table className="table table-one">
                    <thead className="table-secondary table-secondary--darken ">
                      <tr>
                        <th scope="col">Document Name</th>
                        <td align="center">
                          Read / Download
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {legal_documents.map((document, ind) => (
                        <tr key={'docu-'+ind}>
                          <th scope="row">
                            {" "}
                            {document.document_name[0].text}{" "}
                          </th>
                          <td align="center">
                            <a
                              href={document.document_file.url}
                              target="_blank" rel="noreferrer"
                            >
                              <img src={IMAGES.PDF_FILE_ICON} alt="" />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
